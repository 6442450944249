const wsuClassAdd = ( element, elementClass ) => {

    element.classList.add( elementClass );

}

const wsuClassRemove = ( element, elementClass ) => {

    element.classList.remove( elementClass );

}

const wsuClassToggle = ( element, elementClass ) => {


}

export { wsuClassAdd, wsuClassRemove, wsuClassToggle }